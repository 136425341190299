import { onError } from "@apollo/client/link/error"
import BreakingVersionDetector from "@digits-shared/components/Error/BreakingVersionDetector"
import { GRPCErrorCode } from "@digits-shared/grpc/codes"
import Session from "@digits-shared/session/Session"

const tracker = process.env.GRAPHQL_ENDPOINT?.split("/").pop() === "graphql" ? TrackJS : undefined

export default (session: Session) => {
  const breakingVersionDetector = new BreakingVersionDetector(session)

  return onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
      // find one of the following codes in the response and take action
      graphQLErrors.some(async (error) => {
        const { extensions } = error
        const code = extensions?.code
        switch (code) {
          case GRPCErrorCode.Unauthenticated:
            tracker?.console.info("User is unauthenticated, logging out of session")
            await session.clear({
              code,
              message: "Your session has expired. Please log back in.",
            })
            return true

          // Check for breaking version if we've hit any of these errors. If a breaking
          // version has changed then an app reload may be triggered.
          case GRPCErrorCode.InvalidArgument:
          case GRPCErrorCode.NotFound:
          case GRPCErrorCode.Unimplemented:
            breakingVersionDetector.checkBreakingVersion()
            return true
        }

        return false
      })
    }
  })
}
