import promiseHelper from "@digits-shared/helpers/promises/promiseHelper"
import { readableStreamFromBase64 } from "@digits-shared/helpers/streams/streamHelper"
import { JWTSession } from "@digits-shared/session/jwt/jwtSession"
import { jwtDecode } from "jwt-decode"

const COMPRESSED_HEADER = "gz:"

type JWTParsed<JWT extends JWTSession = JWTSession> = {
  encodedSignedJWT: string
  decodedSignedJWT: JWT
  encodedTruncatedJWT: string
  decodedUnsignedJWT: JWT
}

export async function jwtParse<JWT extends JWTSession = JWTSession>(
  jwt: string
): Promise<JWTParsed<JWT>> {
  const decompressedJWT = await decompressJWT(jwt)
  const encodedSignedJWT = jwt
  const decodedSignedJWT = jwtDecode<JWT>(decompressedJWT)

  const encodedTruncatedJWT = stripEncodedJWTSignature(decompressedJWT)
  const decodedUnsignedJWT = jwtDecode<JWT>(encodedTruncatedJWT)

  return { encodedSignedJWT, decodedSignedJWT, encodedTruncatedJWT, decodedUnsignedJWT }
}

function decompressJWT(jwt: string) {
  const [_, base64CompressedJWT] = jwt.split(COMPRESSED_HEADER)
  if (!base64CompressedJWT) {
    return jwt
  }

  let decompressedStream: ReadableStream
  try {
    const readableStream = readableStreamFromBase64(base64CompressedJWT)
    decompressedStream = readableStream.pipeThrough(new DecompressionStream("gzip"))
  } catch (e) {
    TrackJS?.track(e)
    return jwt
  }

  return promiseHelper
    .makeStream(decompressedStream)
    .then((buffers: Uint8Array[]) =>
      buffers
        .flatMap((buffer) => Array.from(buffer).map((byte) => String.fromCharCode(byte)))
        .join("")
    )
    .catch((e) => {
      TrackJS?.track(e)
      return jwt
    })
}

// Remove JWT signature which is after the 2nd dot of the encoded JWT
// (e.g. headerxxx.payloadyyy.signaturezzz)
export const stripEncodedJWTSignature = (encodedSignedJWT: string) =>
  encodedSignedJWT.split(".").slice(0, 2).join(".")
