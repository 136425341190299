import { matchPath } from "react-router-dom"
import { DigitsRoute, Routes, StaticRoutes } from "@digits-shared/components/Router/DigitsRoute"
import History, { Location } from "history"

export const ROUTE_NOT_FOUND_NAME = "notFound"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type HistoryLocation = History.Location<Record<string, any>>

export interface DigitsLocation extends HistoryLocation {
  name: keyof StaticRoutes
  queryParams: Record<string, string>
  fullPathname: string
}

/**
 * Sets custom properties needed on the Location object used throughout the webapp. This must
 * be a mutation because we want to modify the location reference on the history object rather
 * than reassigning. Reassigning will break how react router uses history location under the covers.
 */
export const mutateLocationWithDigitsProperties = (
  location: Location,
  routes: Routes<StaticRoutes>
) => {
  const digitsLocation = location as Partial<DigitsLocation> & Location

  // Name is the corresponding name of the route provided in src/config/routes
  // Helpful when UI needs to know what the current route the user is on.
  if (!digitsLocation.name) digitsLocation.name = getLocationName(digitsLocation, routes)

  // Parse search portion of url for easier accessing and centralized logic for parsing
  if (!digitsLocation.queryParams) {
    // Due to using window push state in period nav, react router can lose the search string
    // so fallback to window.location search if location.search is empty
    digitsLocation.queryParams = getLocationQueryParams(digitsLocation)
  }

  // Concatenate pathname + search parts
  if (!digitsLocation.fullPathname) digitsLocation.fullPathname = getFullPathname(digitsLocation)
}

function getFullPathname(location: Location) {
  const { pathname, search } = location
  return pathname + search
}

// Loop over all routes and test the routes regex representation of it's
// url with the current location and return the routes name if it's found.
function getLocationName(location: Location, routes: Routes<StaticRoutes>) {
  const route = Object.values(routes).find((dRoute: DigitsRoute | unknown) => {
    if (!(dRoute instanceof DigitsRoute)) return false

    // `dRoute.config.parameterizedPath` contains the path without time tokens.
    // A route should match a path even if there is no origin
    const paths = [dRoute.parameterizedPath]
    if (dRoute.config.timeParameterOptions) {
      paths.push(dRoute.config.parameterizedPath)
    }
    return matchPath(location.pathname, paths)?.isExact
  })
  return route?.name || ROUTE_NOT_FOUND_NAME
}

// De-serialize search string from url to an object with key value pairs
// Can handle multiple values in query param (coverts to array)
function getLocationQueryParams(location: Location) {
  const search = location.search || window.location.search

  const queryParams: Record<string, string> = {}
  const searchParams = new URLSearchParams(search)
  searchParams.forEach((value, key) => {
    queryParams[key] = value
  })
  return queryParams
}
