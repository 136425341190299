import * as React from "react"
import { IntervalOrigin } from "@digits-graphql/frontend/graphql-bearer"
import { DigitsLocation } from "@digits-shared/components/Router/DigitsLocation"
import { DigitsRoute, Routes, StaticRoutes } from "@digits-shared/components/Router/DigitsRoute"
import { LoggedRedirect } from "@digits-shared/components/Router/LoggedRedirect"
import dateTimeHelper from "@digits-shared/helpers/dateTimeHelper"
import useRouter from "@digits-shared/hooks/useRouter"

export interface TimeProps {
  intervalOrigin: IntervalOrigin
}

/**
 * Context Consumer and Provider for Interval Origin
 */
export const TimeContext = React.createContext<IntervalOrigin>(
  dateTimeHelper.defaultIntervalOrigin()
)

interface TimeContextURLProviderProps {
  name?: string
  routes: Routes<StaticRoutes>
  children?: React.ReactNode
}

/**
 * Self contained React Component that determines and sets the interval origin based on the current
 * route.
 */
export const TimeContextURLProvider: React.FC<TimeContextURLProviderProps> = ({
  name,
  children,
  routes,
}) => {
  const { location } = useRouter()
  const { queryParams } = location

  // props.match is not populated with time params since this is loaded always via the `children`
  // prop on the SessionRoute above. So we need to build one.
  // e.g. route /foo/:bar/:baz, path => /foo/forks/spoons => { bar: "forks", baz: "spoons" }
  const route = routes[location.name] as DigitsRoute

  const locationParams = React.useMemo(
    () => route?.getParametersFromPath(location.pathname) || {},
    [route, location.pathname]
  )

  // Deconstructing the time params because locationParams & queryParams will be a new object when any other param is added,
  // we only want to recreate the intervalOrigin when the time param changes.

  const { year, index, interval, intervalCount } = React.useMemo(
    () => ({
      year: locationParams.year || queryParams.year,
      index: locationParams.index || queryParams.index,
      interval: locationParams.interval || queryParams.interval,
      intervalCount: locationParams.intervalCount || queryParams.intervalCount,
    }),
    [locationParams, queryParams]
  )

  // Keep a copy of the last valid interval origin to use when navigating
  // to routes without time tokens; so we can remember that the current period was
  const prevVals = React.useRef<IntervalOrigin>()

  // Get interval origin from any parameters in the current route
  // Passing in the new params objects will trigger unnecessary renders (interval origin objects used everywhere)
  // use the deconstructed values instead to the memo fn
  const intervalOrigin = React.useMemo(() => {
    if (!route?.config.timeParameterOptions && prevVals.current) {
      return prevVals.current
    }

    const newInterval = dateTimeHelper.intervalOriginFromRoute(
      {
        year,
        index,
        interval,
        intervalCount,
      },
      route?.config.timeParameterOptions
    )
    prevVals.current = newInterval
    return newInterval
  }, [year, index, interval, intervalCount, route?.config.timeParameterOptions, prevVals])

  // Check to see if current route is a path without or invalid time parameters. If so
  // redirect to the same route with the time tokens correctly in the path
  const redirectPath = getRedirectPathIfInvalidTimeToken(
    intervalOrigin,
    location,
    routes,
    route,
    locationParams
  )
  if (redirectPath)
    return <LoggedRedirect name="TimeContextURLProvider-invalidTimeToken" to={redirectPath} />

  return <TimeContext.Provider value={intervalOrigin}>{children}</TimeContext.Provider>
}

function getRedirectPathIfInvalidTimeToken(
  intervalOrigin: IntervalOrigin,
  location: DigitsLocation,
  routes: Routes<StaticRoutes>,
  route: DigitsRoute,
  params: Record<string, string>
) {
  if (!route?.config.timeParameterOptions) return undefined

  const correctFullPathname = route.generateFromCurrentPath(intervalOrigin, "includeAllQueryParams")

  if (!dateTimeHelper.areRouteAndIntervalOriginEqual(params, intervalOrigin, true)) {
    return correctFullPathname
  }

  return undefined
}
