import { ApolloLink, Observable, Operation } from "@apollo/client"
import {
  GRAPHQL_TRACE_KEY,
  GraphQLTrace,
  GraphQLTraceKind,
} from "@digits-shared/components/Contexts/GraphQLTracerContext"
import Session from "@digits-shared/session/Session"
import { GraphQLErrorExtensions } from "graphql/error/GraphQLError"
import { v4 as generateUUID } from "uuid"

const SLOW_TRACE_THRESHOLD_MS = 2500

const DG_MUTATION_ERROR = "doppelgangers cannot perform mutations"

export type TraceCallback = (trace: GraphQLTrace) => void

export type CustomGraphQLErrorExtensions = GraphQLErrorExtensions & {
  code?: string
  originalMessage?: string
}

export default (session: Session, onTrace?: TraceCallback) =>
  new ApolloLink(
    (operation, forward) =>
      new Observable((observer) => {
        forward(operation)
          .forEach((response) => {
            if (
              onTrace &&
              response.extensions?.span &&
              response.extensions.span > SLOW_TRACE_THRESHOLD_MS
            ) {
              handleSlowTrace(session, onTrace, operation, response.extensions.span)
            }

            // Ignore silenced errors as they have already been deemed ignorable. Still including errors
            // for DG mutations as we'll want to display them in GQLTrace UI.
            if (
              onTrace &&
              response.errors?.some(
                (e) => !e.extensions?.silence || e.message === DG_MUTATION_ERROR
              )
            ) {
              // Note: we send the trace whether or not the trace dev tools are enabled, because
              // we will force enable them to show this, if the current user is an employee.
              let kind = GraphQLTraceKind.ERROR

              if (response.errors.some((e) => e.message === DG_MUTATION_ERROR)) {
                kind = GraphQLTraceKind.DG_MUTATION
              }

              const firstError = response?.errors?.[0]
              const firstErrorExtensions = firstError?.extensions

              onTrace({
                id: generateUUID(),
                kind,
                operation,
                durationMS: response.extensions?.span ?? 0,
                recordedAt: Date.now(),
                errorCode: isCustomErrorExtensions(firstErrorExtensions)
                  ? firstErrorExtensions?.code
                  : undefined,
                errorMessage:
                  isCustomErrorExtensions(firstErrorExtensions) &&
                  typeof firstErrorExtensions?.originalMessage === "string"
                    ? firstErrorExtensions.originalMessage
                    : firstError?.message,
                errorPath: firstError?.path?.slice(),
              })
            }

            observer.next(response)
            observer.complete()
          })
          .catch((error) => observer.error(error))
      })
  )

function isCustomErrorExtensions(
  extensions?: GraphQLErrorExtensions
): extensions is CustomGraphQLErrorExtensions {
  return (
    extensions !== undefined &&
    ((extensions as CustomGraphQLErrorExtensions).originalMessage !== undefined ||
      (extensions as CustomGraphQLErrorExtensions).code !== undefined)
  )
}

function handleSlowTrace(
  session: Session,
  onSlowTrace: (trace: GraphQLTrace) => void,
  operation: Operation,
  durationMS: number
) {
  const id = generateUUID()
  onSlowTrace({ id, kind: GraphQLTraceKind.SLOW, operation, durationMS, recordedAt: Date.now() })

  const traceDevToolsEnabled = session.getBooleanUserPreference(GRAPHQL_TRACE_KEY, true)

  // Output the trace of the call stack to provide a crude aid in determine call site.
  /* eslint-disable no-console */
  if (traceDevToolsEnabled) {
    console.groupCollapsed(operation.operationName, `${durationMS}ms`)
    console.log("uuid:", id)
    console.groupCollapsed("variables")
    console.log(operation.variables)
    console.groupEnd()
    console.groupCollapsed("query")
    console.log(operation.query.loc?.source.body)
    console.groupEnd()
    console.groupCollapsed("stack trace")
    console.trace()
    console.groupEnd()
    console.groupEnd()
  }
  /* eslint-enable no-console */
}
